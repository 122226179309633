import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

export default function SearchBox() {
  const { handleSubmit, register, setValue } = useForm()

  const router = useRouter()

  function search(data) {
    const q = data.q.trim()

    router.push(`/products/?q=${q}`)
  }

  useEffect(() => {
    const url = new URLSearchParams(window.location.search)

    setValue('q', url.get('q') || '')
  })

  return (
    <form onSubmit={handleSubmit(search)}>
      <div className="input-group">
        <span className="input-group-text bg-white border-end-0">
          <FontAwesomeIcon icon={faSearch} className="text-muted" />
        </span>
        <input
          type="text"
          className="form-control border-start-0 no-border-shadow"
          placeholder="Search"
          name="q"
          ref={register}
        />
      </div>
    </form>
  )
}
