import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

export default function SectionIcon({ icon, className, size }) {
  return (
    <div
      className={classNames(
        'section-icon d-inline-flex align-items-center justify-content-center rounded-circle',
        className
      )}
    >
      <FontAwesomeIcon icon={icon} size={size} />
    </div>
  )
}
