import AuthWidget from './AuthWidget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { useRouter } from 'next/router'

export default function AdminNav({ pageProps, tabs }) {
  const mobilePlus = useMediaQuery({ query: '(min-width: 768px)' })
  const router = useRouter()

  const { shop } = pageProps

  function closeMenu() {
    if (!mobilePlus) {
      const navToggler = document.getElementById('nav-toggler')
      navToggler.click()
    }
  }

  if (!pageProps?.shop) {
    return null
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom px-3">
      <Link href="/">
        <a className="navbar-brand fw-bold fs-5 text-primary">{shop.title}</a>
      </Link>

      <button
        className="navbar-toggler border-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navMenu"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        id="nav-toggler"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navMenu">
        <div className="navbar-nav me-auto border-start ps-3 d-none d-lg-block">
          <Link href="/">Go to site</Link>
        </div>

        <ul className="navbar-nav">
          <li className="d-inline d-lg-none">
            <div className="d-inline-block py-3">
              <p className="h6 mb-0">{shop.title} Dashboard</p>
              <small className="text-muted">{shop?.tagline}</small>
            </div>
          </li>

          {tabs.map((tab) => {
            const selected = router.pathname.startsWith(tab.path)

            return (
              <li
                key={tab.path}
                className={classNames('px-3 py-2 hover-light d-inline d-lg-none rounded', {
                  'bg-primary text-white': selected,
                })}
                onClick={closeMenu}
              >
                <Link href={tab.path} passHref>
                  <a className={classNames('d-flex cursor-pointer', { 'text-white': selected })}>
                    <span style={{ width: 40 }} className="d-inline-block text-center">
                      <FontAwesomeIcon
                        icon={tab.icon}
                        className={'me-3 ' + (selected ? 'text-white' : 'text-muted')}
                      />
                    </span>
                    <span>{tab.title}</span>
                  </a>
                </Link>
              </li>
            )
          })}

          <li className="d-inline d-lg-none">
            <hr />
          </li>

          <AuthWidget shop={shop} />
        </ul>
      </div>
    </nav>
  )
}
