import { faBell, faShoppingBag, faShoppingBasket, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import PubNav from '../components/PubNav'
import classNames from 'classnames'
import { useRouter } from 'next/router'

const tabs = [
  { icon: faUser, path: '/account/profile', title: 'Profile' },
  { icon: faBell, path: '/account/notifications', title: 'Notifications' },
  { icon: faShoppingBag, path: '/account/orders', title: 'Orders' },
  { icon: faShoppingBasket, path: '/account/checkouts', title: 'Shared Checkouts' },
]

export default function AccountLayout({ pageProps, children }) {
  const router = useRouter()

  return (
    <>
      <PubNav pageProps={pageProps} />
      <div className="container">
        <div className="row">
          <div className="col-lg-3 py-2">
            <div className="sticky-top">
              <div className="px-2 d-flex">
                <div>
                  <p className="h6 font-bold mb-0">{pageProps.shop.title}</p>
                  <p className="text-muted">{pageProps.shop.tagline}</p>
                </div>
              </div>

              <ul className="list-unstyled mt-3">
                {tabs.map((tab) => {
                  const selected = router.pathname.startsWith(tab.path)

                  return (
                    <li
                      key={tab.path}
                      className={classNames('px-3 py-2 hover-light rounded', {
                        'bg-primary text-white': selected,
                      })}
                    >
                      <Link href={tab.path}>
                        <a className={'inline-flex ' + (selected ? 'text-white' : '')}>
                          <span
                            style={{ display: 'inline-block', width: 40 }}
                            className="text-center"
                          >
                            <FontAwesomeIcon
                              icon={tab.icon}
                              className={'me-3 ' + (selected ? 'text-white' : 'text-muted')}
                            />
                          </span>
                          <span>{tab.title}</span>
                        </a>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div className="col-lg-9" style={{ minHeight: '80vh' }}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
