import {
  faBoxes,
  faChartBar,
  faCog,
  faGlobeAfrica,
  faPeopleCarry,
  faPercentage,
  faShoppingBasket,
  faStarHalfAlt,
} from '@fortawesome/free-solid-svg-icons'
import AdminNav from '../components/AdminNav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import classNames from 'classnames'
import useAuth from '../lib/hooks/use-auth'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

const tabs = [
  { icon: faChartBar, path: '/admin/overview', title: 'Overview' },
  { icon: faBoxes, path: '/admin/products', title: 'Products' },
  { icon: faShoppingBasket, path: '/admin/orders', title: 'Orders' },
  { icon: faGlobeAfrica, path: '/admin/site', title: 'Site' },
  { icon: faStarHalfAlt, path: '/admin/reviews', title: 'Reviews' },
  { icon: faPercentage, path: '/admin/discount-codes', title: 'Discount Codes' },
  { icon: faPeopleCarry, path: '/admin/management', title: 'Management' },
  { icon: faCog, path: '/admin/settings', title: 'Settings' },
]

const roleExclusions = {
  keeper: ['/admin/discount-codes', '/admin/management', '/admin/settings'],
  manager: ['/admin/management', '/admin/settings'],
}

export default function AdminLayout({ children, pageProps }) {
  const router = useRouter()
  const auth = useAuth()

  const scopedTabs = useMemo(() => {
    return tabs.filter((tab) => {
      const exclusions = roleExclusions[auth.role?.role] || []
      return !exclusions.includes(tab.path)
    })
  }, [auth])

  return (
    <>
      <AdminNav pageProps={pageProps} tabs={scopedTabs} />
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 bg-light py-2 px-0 d-none d-lg-block">
              <div className="sticky-top">
                <div className="px-2 d-flex">
                  {pageProps.shop.logo && (
                    <>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={pageProps.shop.logo.thumbnail}
                        alt={pageProps.shop.title}
                        className="brand-logo me-2"
                      />
                    </>
                  )}

                  <div>
                    <p className="h6 font-bold mb-0">{pageProps.shop.title} Dashboard</p>
                    <p className="text-muted">{pageProps.shop.tagline}</p>
                  </div>
                </div>

                <ul className="list-unstyled mt-3">
                  {scopedTabs.map((tab) => {
                    const selected = router.pathname.startsWith(tab.path)

                    return (
                      <li
                        key={tab.path}
                        className={classNames('px-3 py-2', {
                          'bg-primary text-white': selected,
                          'hover-light': !selected,
                        })}
                      >
                        <Link href={tab.path}>
                          <a className={classNames('d-flex', { 'text-white': selected })}>
                            <span
                              style={{ display: 'inline-block', width: 40 }}
                              className="text-center"
                            >
                              <FontAwesomeIcon
                                icon={tab.icon}
                                className={classNames('me-3', {
                                  'text-muted': !selected,
                                  'text-white': selected,
                                })}
                              />
                            </span>
                            <span>{tab.title}</span>
                          </a>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className="col-lg-10" style={{ minHeight: '90vh' }}>
              <div className="container p-1">{children}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
