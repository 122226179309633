import { getPreference, removePreference } from '../preferences'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ClientContext } from '../contexts/client'
import { getRole } from '../api'
import { isAdminRole } from '../utils'

export default function useAuth() {
  const [user, setUser] = useState()
  const [role, setRole] = useState()

  const { shop } = useContext(ClientContext)

  const loadUser = useCallback(async () => {
    if (!shop._id) return

    try {
      setUser(getPreference('user'))
      setRole(await getRole(shop._id))
    } catch (err) {
      setUser(null)
    }
  }, [shop?._id])

  function logout() {
    removePreference('user')
    removePreference('authCredentials')
  }

  useEffect(() => {
    loadUser()
  }, [loadUser])

  return { isAdmin: isAdminRole(role?.role), isAuthenticated: Boolean(user), logout, role, user }
}
