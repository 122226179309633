import axios from 'axios'
import { getPreference } from './preferences'

export const baseURL = process.env.NEXT_PUBLIC_API_URL // fixes build issues

const requests = axios.create({ baseURL })

requests.interceptors.request.use(async (config) => {
  config.withCredentials = Boolean(global.window)

  try {
    const { access_token: jwt, token_type } = getPreference('authCredentials')

    config.headers = Object.assign(config.headers || {}, {
      Authorization: `${token_type} ${jwt}`,
    })

    return config
  } catch (err) {
    return config
  }
})

requests.interceptors.response.use(
  async (response) => {
    return response
  },
  async (err) => {
    if (err.response) {
      if (process.env.NODE_ENV === 'development') {
        console.log(err.response.status)
        console.log(err.response.data)
      }
    }

    // TODO: if auth error redirect to login

    throw err
  }
)

export default requests
