import { getPreference, setPreference } from './preferences'
import axios from 'axios'
import requests from './requests'

// self
export async function getToken(code) {
  const res = await axios.post(
    '/api/token/',
    { code },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )

  return res.data
}

export async function requestLogin(origin) {
  const { data } = await axios.get(`/api/request-login?redir=${origin}`)
  return data
}

// main

export async function updateShop(shopId, data) {
  const res = await requests.patch(`/api/shops/${shopId}`, data)
  return res.data
}

export async function identifyUser() {
  const res = await requests.get('/api/user/')
  return res.data
}

export async function getProducts(filter) {
  const customKeys = ['sort', 'direction', 'q', 'stock']

  const allowedFields = [...customKeys, 'category', 'shop', 'available', '$skip', '$limit']

  const q = []

  const customs = {}

  Object.keys(filter).forEach((f) => {
    const fi = filter[f]
    if (![undefined, null].includes(fi)) {
      if (customKeys.includes(f)) {
        customs[f] = fi
      } else if (allowedFields.includes(f)) {
        q.push(`${f}=${fi}`)
      }
    }
  })

  const direction = (customs['direction'] || 'desc') === 'desc' ? -1 : 1

  // handle sort and direction
  const sort = customs['sort']

  if (sort) {
    const sortKey = sort === 'newest' ? 'createdAt' : 'price.value'
    q.push(`$sort[${sortKey}]=${direction}`)
  } else {
    // default sort is date
    // 1 asc -1 desc
    q.push(`$sort[createdAt]=${direction}`)
  }

  const search = customs['q']

  if (search) {
    q.push(`title[$q]=${search}`)
  }

  const stock = customs['stock'] // in-stock, out-of-stock, all

  if (stock && stock !== 'all') {
    q.push(`inStock=${stock === 'in-stock'}`)
  }

  const params = q.join('&')

  const res = await requests.get(`/api/products/?${params}`)
  return res.data
}

export async function getCategories(shopId) {
  const res = await requests.get(`/api/categories/?shop=${shopId}`)
  return res.data
}

export async function createCategory(data) {
  const res = await requests.post('/api/categories/', data)
  return res.data
}

export async function createProduct(data) {
  const res = await requests.post('/api/products/', data)
  return res.data
}

export async function getProduct(id) {
  const res = await requests.get(`/api/products/${id}/`)
  return res.data
}

export async function createOrder(order, anonymous) {
  const res = await requests.post(
    `/api/orders/?email=${anonymous?.email ?? ''}&shop=${anonymous?.shop ?? ''}`,
    order
  )
  return res.data
}

export async function getOrders(filter = {}) {
  const q = []

  const asIsParams = ['$skip', '$limit']
  Object.entries(filter).forEach(([k, v]) => {
    if (asIsParams.includes(k)) {
      q.push(`${k}=${v}`)
    }
  })

  if (filter.profile) {
    q.push(`profile=${filter.profile}`)
  }

  if (filter.shop) {
    q.push(`shop=${filter.shop}`)
  }

  if (filter.status) {
    const statusFilter = filter.status
      .map((s, i) => {
        return `$or[${i}][status]=${s}`
      })
      .join('&')

    q.push(statusFilter)
  }

  const query = filter.query?.trim()

  if (query) {
    q.push(`orderId[$q]=${query}`)
  }

  if (filter.startDate) {
    q.push(`createdAt[$gte]=${filter.startDate.getTime()}`)
  }

  if (filter.endDate) {
    q.push(`createdAt[$lte]=${filter.endDate.getTime()}`)
  }

  const res = await requests.get(`/api/orders/?$sort[createdAt]=-1&acked=true&${q.join('&')}`)
  return res.data.data
}

export async function getOrder(orderId) {
  const res = await requests.get(`/api/orders/${orderId}`)
  return res.data
}

export async function updateOrder(orderId, update) {
  const res = await requests.patch(`/api/orders/${orderId}`, update)

  return res.data
}

export async function getRole(shopId) {
  const res = await requests.get(`/api/role/?shop=${shopId}`)
  return res.data
}

export async function trackEvent(event, attempt = 0) {
  if (attempt > 1) {
    return
  }

  try {
    const { trackingId, expires } = getPreference('tracking')

    if (Date.now() > expires) {
      throw new Error('Tracking ID expired')
    }

    return await requests.post(`/api/analytics?trackingId=${trackingId}`, event)
  } catch (err) {
    // Doesn't exists or expired
    const {
      data: { trackingId, maxAge },
    } = await requests.post('/api/tracking-ids', {})

    setPreference('tracking', { expires: Date.now() + maxAge, trackingId })

    return await trackEvent(event, attempt + 1)
  }
}

export async function getAnalytics(filter = {}) {
  const q = []

  if (filter.from) {
    q.push(`createdAt[$gte]=${filter.from.getTime()}`)
    delete filter.from
  }

  if (filter.to) {
    q.push(`createdAt[$lte]=${filter.to.getTime()}`)
    delete filter.to
  }

  Object.entries(filter).forEach(([key, value]) => {
    q.push(`${key}=${value}`)
  })

  const res = await requests.get(`/api/analytics-aggregator/?${q.join('&')}`)
  return res.data
}

export async function getShopDetailAndConfig(shopId) {
  const { data } = await requests.get(`/api/shop-config/${shopId}`)

  return data
}

export async function updateConfig(configId, data) {
  const res = await requests.patch(`/api/shop-configs/${configId}`, data)

  return res.data
}

export async function getSelfRates(shopId) {
  const { data } = await requests.get(`/delivery/rates/?client=${shopId}`)

  return data
}

export async function getRegions() {
  const { data } = await requests.get('/delivery/regions/?country=GH')

  return data
}

export async function createRates(data) {
  const res = await requests.post('/delivery/rates/', data)

  return res.data
}

export async function updateRates(rateId, data) {
  const res = await requests.patch(`/delivery/rates/${rateId}`, data)

  return res.data
}

export async function getInventory(filter = {}) {
  const q = []

  if (filter.product) {
    q.push(`product=${filter.product}`)
  }

  if (filter.variant) {
    q.push(`variant=${filter.variant}`)
  }

  const { data } = await requests.get(`/api/inventory/?${q.join('&')}`)

  return data
}

export async function createCheckout(form, anonymous) {
  const { data } = await requests.post(
    `/api/checkouts/?email=${anonymous?.email || ''}&shop=${anonymous?.shop || ''}`,
    form
  )

  return data
}

export async function getCheckout(checkoutId) {
  const { data } = await requests.get(`/api/checkouts/${checkoutId}`)

  return data
}

export async function getCheckouts(filter = {}) {
  const q = []

  if (filter.profile) {
    q.push(`profile=${filter.profile}`)
  }

  if (filter.shop) {
    q.push(`shop=${filter.shop}`)
  }

  const { data } = await requests.get(`/api/checkouts/?${q.join('&')}`)

  return data
}

export async function calculateSelfServiceDelivery(form) {
  const { data } = await requests.post('/delivery/calculate', form)

  return data
}

export async function getDiscountCodes(shopId) {
  const { data } = await requests.get(`/api/discount-codes/?$sort[createdAt]=-1&shop=${shopId}`)

  return data
}

export async function createDiscountCode(form) {
  const { data } = await requests.post('/api/discount-codes/', form)

  return data
}

export async function getDiscountCode(code, shopId) {
  const { data } = await requests.get(`/api/discount-codes/?code=${code}&shop=${shopId}`)

  return data[0]
}

export async function editProduct(productId, form) {
  const { data } = await requests.patch(`/api/products/${productId}`, form)

  return data
}

export async function createRoleInvitation(form) {
  const { data } = await requests.post('/api/shop-role-invitations/', form)

  return data
}

export async function getRoleInvitations(shopId) {
  const { data } = await requests.get(`/api/shop-role-invitations/?shop=${shopId}&status=pending`)

  return data
}

export async function getShopRoles(shopId) {
  const { data } = await requests.get(`/api/shop-roles/?shop=${shopId}&status=active`)

  return data
}

export async function createShopIdentification(form) {
  const { data } = await requests.post('/api/shop-ids/', form)
  return data
}

export async function createFinancialAccount(form) {
  const { data } = await requests.post('/api/financial-accounts/', form)
  return data
}

export async function getFinancialAccount(shopId) {
  const { data } = await requests.get(`/api/financial-accounts/${shopId}`)

  return data
}

export async function updateTransaction(transactionId, form) {
  const { data } = await requests.patch(`/api/transactions/${transactionId}`, form)

  return data
}

export async function getReviews(filter = {}) {
  const q = []

  if (filter.shop) {
    q.push(`shop=${filter.shop}`)
  }

  if (filter.order) {
    q.push(`relatedOrder=${filter.order}`)
  }

  const { data } = await requests.get(`/api/reviews/?${q.join('&')}`)

  return data
}

export async function getRating(shopId) {
  const { data } = await requests.get(`/api/ratings/?shop=${shopId}`)

  return data[0]
}

export async function createReview(form) {
  const { data } = await requests.post('/api/reviews/', form)

  return data
}

export async function updateDiscountCode(discountId, form) {
  const { data } = await requests.patch(`/api/discount-codes/${discountId}`, form)

  return data
}

export async function getNotifications(filter = {}) {
  const q = []

  if (filter.profile) {
    q.push(`profile=${filter.profile}`)
  }

  if (filter.shop) {
    q.push(`shop=${filter.shop}`)
  }

  const { data } = await requests.get(`/api/notifications/?$sort[createdAt]=-1&${q.join('&')}`)

  return data
}

export async function updateNotification(notificationId, form) {
  const { data } = await requests.patch(`/api/notifications/${notificationId}`, form)

  return data
}

export async function updateInvitation(invitationId, form) {
  const { data } = await requests.patch(`/api/shop-role-invitations/${invitationId}`, form)

  return data
}

export async function updateShopRole(roleId, form) {
  const { data } = await requests.patch(`/api/shop-roles/${roleId}`, form)

  return data
}

export async function getInvitation(invitationId) {
  const { data } = await requests.get(`/api/shop-role-invitations/${invitationId}`)
  return data
}

export async function getNotificationsHint() {
  const { data } = await requests.get('/api/notifications-hint')
  return data
}

export async function getPaymentInfo(shopId) {
  const { data } = await requests.get(`/api/payment-info/?shop=${shopId}`)

  return data
}

export async function getSales(shopId, filter = {}) {
  const q = []

  if (filter.from) {
    q.push(`from=${filter.from}`)
  }

  if (filter.to) {
    q.push(`to=${filter.to}`)
  }

  const { data } = await requests.get(`/api/sales/${shopId}?${q.join('&')}`)

  return data
}

export async function deleteCategory(categoryId) {
  await requests.delete(`/api/categories/${categoryId}`)
}

export async function deleteProduct(productId) {
  await requests.delete(`/api/products/${productId}`)
}
