import { getShopDetailAndConfig, requestLogin } from './api'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export function summarizeVariant(variant) {
  return tagsFromVariant(variant).join(' | ')
}

export function tagsFromVariant(variant) {
  return Object.keys(variant)
    .filter((v) => Boolean(variant[v]))
    .map((i) => {
      if (i === 'size') {
        return `Size ${variant[i]}`
      }
      return variant[i]
    })
}

export function getVariantFromProduct(product, variantId) {
  const { features } = product.variants.find((v) => v._id === variantId)
  return features
}

/**
 * Replaces all consecutive non-word character with hyphen
 * @param {string} t the string to slugify
 */
export const slugify = (t) => {
  return t.toLowerCase().replace(/[^a-zA-Z\d]+/gi, '-')
}

export async function getShopConfiguration(shopId) {
  const { shop, config } = await getShopDetailAndConfig(shopId)

  // TODO: [Later] if shop is also deactivated, or taken down, return notfound
  return {
    props: { config, shop },
  }
}

export function formatListTime(date) {
  return dayjs(date).fromNow()
}

export function getStatusWord(status) {
  const map = {
    canceled: 'Canceled',
    delivered: 'Delivered',
    dispatched: 'Dispatched',
    packaged: 'Packaged',
    placed: 'Placed',
    ready: 'Ready',
  }

  return map[status]
}

export function getSellingPrice(product) {
  if (product.sellAt > 0) {
    return product.sellAt
  }

  return product.price.value
}

export function getSellingPriceWithCurrency(product) {
  return `${product.price.currency} ${getSellingPrice(product)}`
}

export function getItemsTotal(order) {
  return order.items.map((i) => getSellingPrice(i.product) * i.count).reduce((x, y) => x + y)
}

export function getOrderTotal(order) {
  const itemsTotal = getItemsTotal(order)

  const delivery = getDelivery(order)

  return itemsTotal + delivery - (order.discount?.value ?? 0)
}

export function getOrderCurrency(order) {
  return order.items[0].product.price.currency
}

export function getRelativeTime(time) {
  // 12:30, Yesterday 17:12, Date
  if (dayjs(new Date()).isSame(time, 'date')) {
    return dayjs(time).format('HH:mm')
  }

  if (dayjs(new Date()).subtract(1, 'day').isSame(time, 'date')) {
    return dayjs(time).format('[Yesterday,] HH:mm')
  }

  return dayjs(time).format('DD MMM')
}

export function getRelativeTimeVerbose(time) {
  // 12:30, Yesterday 17:12, Date
  if (dayjs(new Date()).isSame(time, 'date')) {
    return dayjs(time).format('[Today at] HH:mm')
  }

  if (dayjs(new Date()).subtract(1, 'day').isSame(time, 'date')) {
    return dayjs(time).format('[Yesterday at] HH:mm')
  }

  return dayjs(time).format('[on] DD MMMM YYYY ')
}

export function cartContains(cart, product, variant) {
  return Object.keys(cart).includes([product._id, variant].toString())
}

export function getSocials(shopConfig) {
  if (!shopConfig || !shopConfig.shop) return []

  return shopConfig.shop.socials
}

export async function login() {
  const { loginUrl } = await requestLogin(window.location.origin)
  window.location.href = loginUrl
}

/**
 *
 * @param {object} product
 * @param {number} count
 * @returns the total for a product
 */
export function calculateItemTotal(product, count) {
  const price = getSellingPrice(product)
  return `${product.price.currency} ${(price * count).toFixed(2)}`
}

// acts like extension function
// usage: extend an object with this function. eg. cart.getGrossTotal = utils.getGrossTotal
export function calculateGrossTotal({ items }) {
  const itemsToCalculate = Object.keys(items).map((k) => items[k])

  if (itemsToCalculate.length < 1) return 0

  const itemsTotals = itemsToCalculate.map((it) => getSellingPrice(it.product) * it.count)

  return itemsTotals.reduce((a, b) => a + b)
}

export function calculateNet(checkout, considerDeliveryPaymentOption) {
  const tax = checkout.tax || 0
  const grossTotal = calculateGrossTotal(checkout)

  let discount = 0

  const { discount: cDiscount } = checkout
  if (!cDiscount.error && cDiscount.discount && grossTotal > 0) {
    if (cDiscount.discount.type === 'percentage') {
      discount = grossTotal * (cDiscount.discount.value / 100)
    } else {
      discount = cDiscount.discount.value
    }
  }

  let delivery = 0

  if (checkout.delivery.type === 'dispatch') {
    const deliveryCalc = (checkout.delivery.rate || 0) < 1 ? 0 : checkout.delivery.rate.rate.value

    if (considerDeliveryPaymentOption) {
      if (checkout.delivery.rate?.paymentOption === 'instant') {
        delivery = deliveryCalc
      }
    } else {
      delivery = deliveryCalc
    }
  }

  return grossTotal + tax + delivery - discount
}

export function ultimatumFromDateStr(d) {
  const [year, month, date] = d.split('-')
  return dayjs()
    .date(date)
    .month(month - 1)
    .year(year)
    .hour(23)
    .minute(59)
    .second(59)
    .toDate()
}

export function getCleanDate(d) {
  return dayjs(d).format('DD MMM YYYY')
}

export function translateDiscount(discount) {
  const { value, type } = discount

  if (type === 'percentage') {
    return `${value}%`
  }

  return `-GHS ${discount.value}`
}

export function getOrderSummary(order) {
  const first = order.items[0].product.title

  if (order.items.length > 1) {
    return `${first} and ${order.items.length - 1} other items`
  }

  return first
}

/**
 *
 * @param {string} s
 * @param {number} l max length
 */
export function shortenText(s, l) {
  if (s.length > l) {
    const p = s.substring(0, l)
    return p + '...'
  }

  return s
}

export function getDelivery(order) {
  if (order.delivery.type === 'pick-up') {
    return 0
  }

  return order.delivery.rate.rate.value
}

export function getThumbnail(order) {
  return order.items[0].product.images[0].thumbnail
}

export function isAdminRole(role) {
  return ['administrator', 'owner', 'manager', 'keeper'].includes(role)
}

export const socialUsernameRegex = new RegExp('https://.+/([a-zA-Z0-9_.-]+)')

export function toDate() {
  const splits = this.split('-')
  return new Date(parseInt(splits[0]), parseInt(splits[1]), parseInt(splits[2]))
}

export function fillInDate(data, startDate, width, field = 'date', value = 0) {
  let cursorDate = dayjs(startDate)
  const endDate = cursorDate.add(width, 'days')

  let cursor = 0

  let res = []

  while (cursorDate.isBefore(endDate)) {
    if (cursor >= data.length || !cursorDate.isSame(dayjs(data[cursor][field]), 'day')) {
      res.push({ label: cursorDate.format('DD MMM'), value: 0 })
    } else {
      const currentData = data[cursor]
      res.push({ label: cursorDate.format('DD MMM'), ...currentData })

      cursor++
    }

    cursorDate = cursorDate.add(1, 'days')
  }

  return res
}
