// Source: https://github.com/FortAwesome/react-fontawesome/issues/134
// This ensures that the icon CSS is loaded immediately before attempting to render icons
import '@fortawesome/fontawesome-svg-core/styles.css'
import '../styles/globals.scss'
import { getPreference, removePreference, setPreference } from '../lib/preferences'
import { getRole, trackEvent } from '../lib/api'
import { isAdminRole, login, socialUsernameRegex } from '../lib/utils'
import { useCallback, useEffect } from 'react'
import AccountLayout from '../layouts/AccountLayout'
import AdminLayout from '../layouts/AdminLayout'
import AuthContextProvider from '../lib/contexts/auth'
import CartContextProvider from '../lib/contexts/cart'
import ClientContextProvider from '../lib/contexts/client'
import { DefaultSeo } from 'next-seo'
import Footer from '../components/Footer'
import Head from 'next/head'
import MainLayout from '../layouts/MainLayout'
import Script from 'next/script'
import { _any } from '../lib/godash'
import { config } from '@fortawesome/fontawesome-svg-core'
import { useRouter } from 'next/router'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

const adminRoute = RegExp('/admin/?.*')

const authenticatedRoutes = [
  adminRoute,
  RegExp('/account/?.*'),
  RegExp('/invitation/?.*'),
  RegExp('/setup/?.*'),
]

function _getLayout(router) {
  if (router.pathname.startsWith('/admin')) {
    return AdminLayout
  } else {
    return router.pathname.startsWith('/account') ? AccountLayout : MainLayout
  }
}

export default function MyApp({ Component, pageProps }) {
  const router = useRouter()

  const { shop, config } = pageProps

  const _Layout = _getLayout(router)

  const adminPermitOrRedirect = useCallback(async () => {
    if (!shop) {
      return
    }

    const role = await getRole(shop._id)

    if (!isAdminRole(role.role)) {
      await router.replace('/')
    }
  }, [shop, router])

  const setUp = useCallback(async () => {
    const { asPath } = router

    if (_any(authenticatedRoutes, (pattern) => pattern.test(asPath))) {
      try {
        getPreference('user') // triggers an exception if no user

        if (adminRoute.test(asPath)) {
          await adminPermitOrRedirect()
        }
      } catch (err) {
        setPreference('checkpoint', window.location.href)
        await login()
        return
      }
    }

    if (new URLSearchParams(window.location.search).has('c')) {
      try {
        const checkpoint = getPreference('checkpoint')
        removePreference('checkpoint')

        await router.push(checkpoint)
      } catch (err) {
        //
      }
    }
  }, [adminPermitOrRedirect, router])

  useEffect(() => {
    setUp()
  }, [shop, setUp])

  useEffect(() => {
    if (shop?._id) {
      trackEvent({
        action: 'site_visit',
        category: 'shop',
        extras: {
          origin: window.location.host,
          shop: shop._id,
          // TODO: Include sources utm_source
        },
      })
    }
  }, [shop?._id])

  const defaultSeo = {
    description: shop?.about ?? 'Our site is still under development. Please come back later',
    title: '',
    titleTemplate: `%s | ${shop?.title || 'Adeton'}`,
  }

  const socials = shop?.socials ?? []

  const twitter = socials.find((s) => s.site === 'twitter')

  if (twitter) {
    const usernameMatch = twitter.url.match(socialUsernameRegex)

    if (usernameMatch) {
      defaultSeo['twitter'] = {
        cardType: 'summary_large_image',
        handle: `@${usernameMatch[1]}`,
      }
    }
  }

  return (
    <>
      <Head>
        {shop?.logo ? (
          <link rel="icon" href={shop.logo.thumbnail} />
        ) : (
          <link
            rel="icon"
            href="https://compa.eu-central-1.linodeobjects.com/adeton/adeton-icon.jpg"
          />
        )}

        {config?.facebook?.meta && (
          <meta name="facebook-domain-verification" content={config.facebook.meta} />
        )}
      </Head>
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4"
        crossOrigin="anonymous"
      />
      <DefaultSeo {...defaultSeo} />
      <ClientContextProvider config={config} shop={shop}>
        <AuthContextProvider>
          {/** TODO: This has to wrap the right pages only */}
          <CartContextProvider>
            <_Layout pageProps={pageProps}>
              <Component {...pageProps} />
            </_Layout>
          </CartContextProvider>
        </AuthContextProvider>
      </ClientContextProvider>
      <Footer pageProps={pageProps} />
    </>
  )
}
