import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Link from 'next/link'
import SectionIcon from './SectionIcon'
import { getSocials } from '../lib/utils'

const socialProps = {
  facebook: {
    icon: faFacebook,
    title: 'Facebook',
  },
  instagram: {
    icon: faInstagram,
    title: 'Instagram',
  },
  twitter: {
    icon: faTwitter,
    title: 'Twitter',
  },
}

export default function Footer({ pageProps }) {
  if (!pageProps.shop) {
    return (
      <footer className="footer bg-light">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <p>
                💚 Made Possible with{' '}
                <a href="https://adeton.io" className="text-secondary">
                  Adeton
                </a>
                .
              </p>
            </div>
            <div className="col-12 col-lg-3 col-md-6 offset-md-1">
              <p className="h6 text-muted mb-3">Adeton</p>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <a href="https://adeton.io" className="text-secondary">
                    🛒 Create your shop
                  </a>
                </li>
                <li className="mt-2">
                  <a href="https://adeton.io/terms" className="link-link">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="mt-2">
                  <a href="https://adeton.io/privacy" className="link-link">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr />
        </div>
      </footer>
    )
  }

  return (
    <footer className="footer bg-light">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-lg-2  col-md-6 pb-md-3 pb-5">
            <p className="h5 mb-0">{pageProps.shop.title}</p>
            <p className="text-muted">{pageProps.shop.tagline}</p>
          </div>

          <div className="col-12 col-lg-3 col-md-6 pb-md-3 pb-5">
            <p className="h6 text-muted mb-3">Shop</p>
            <ul className="list-unstyled">
              <li className="mt-2">
                <Link href="/reviews">
                  <a className="link-link">Reviews</a>
                </Link>
              </li>
              <li className="mt-2">
                <Link href="/about#payments">
                  <a className="link-link">Payments &amp; Delivery</a>
                </Link>
              </li>
              <li className="mt-2">
                <Link href="/about#returns">
                  <a className="link-link">Return &amp; Refund Policy</a>
                </Link>
              </li>
              <li className="mt-2">
                <Link href="/about">
                  <a className="link-link">About us</a>
                </Link>
              </li>
              <li className="mt-2">
                <Link href="/about#contact">
                  <a className="link-link">Contact</a>
                </Link>
              </li>
            </ul>
          </div>

          {getSocials(pageProps).length > 0 && (
            <div className="col-12 col-lg-3 col-md-6 pb-md-3 pb-5">
              <p className="h6 text-muted mb-3">Socials</p>

              <div className="d-flex">
                {getSocials(pageProps).map((social) => {
                  return (
                    <div key={social.site} className="d-inline me-3">
                      <a href={social.url} className="link-link" target="_blank" rel="noreferrer">
                        <SectionIcon
                          icon={socialProps[social.site].icon}
                          className="text-primary bg-primary-light"
                        />
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <div className="col-12 col-lg-3 col-md-6 offset-lg-1">
            <p className="h6 text-muted mb-3">Adeton</p>
            <ul className="list-unstyled">
              <li className="mt-2">
                <a href="https://adeton.io" className="text-secondary">
                  🛒 Create your shop
                </a>
              </li>
              <li className="mt-2">
                <a href="https://adeton.io/privacy-terms#terms" className="link-link">
                  Terms &amp; Conditions
                </a>
              </li>
              <li className="mt-2">
                <a href="https://adeton.io/privacy-terms#privacy" className="link-link">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <p>
          💚 Made Possible with{' '}
          <a href="https://adeton.io" className="text-secondary">
            Adeton
          </a>
          .
        </p>
      </div>
    </footer>
  )
}
