import React, { useCallback, useContext, useEffect, useState } from 'react'
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import AuthWidget from './AuthWidget'
import { CartContext } from '../lib/contexts/cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import SearchBox from './SearchBox'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { getNotificationsHint } from '../lib/api'
import { getSocials } from '../lib/utils'
import { useMediaQuery } from 'react-responsive'

const iconMap = {
  facebook: faFacebook,
  instagram: faInstagram,
  twitter: faTwitter,
}

/**
 * Navbar for shop site
 */
export default function PubNav({ pageProps }) {
  const [hasNotification, setHasNotification] = useState(false)

  const mobilePlus = useMediaQuery({ query: '(min-width: 768px)' })

  const cart = useContext(CartContext)

  function getLogo() {
    if (!pageProps || !pageProps.shop || !pageProps.shop.logo) return

    return pageProps.shop.logo.thumbnail
  }

  function closeMenu() {
    if (!mobilePlus) {
      const navToggler = document.getElementById('nav-toggler')
      navToggler.click()
    }
  }

  // TODO: Move to hook (useNotificationHint)
  const checkForNotification = useCallback(async () => {
    try {
      const notificationsHint = await getNotificationsHint()
      setHasNotification(notificationsHint.count > 0)
    } catch (err) {
      //
    }
  }, [])

  useEffect(() => {
    checkForNotification()
  }, [checkForNotification])

  if (!pageProps?.shop) {
    return null
  }

  const logo = getLogo()
  const showCheckoutDot = cart.items.length > 0

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom px-3">
      <Link href="/">
        <a className="navbar-brand fw-bold fs-5 text-primary cursor-pointer">
          {logo ? (
            <>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={logo} alt={pageProps.shop.title} className="brand-logo" />
            </>
          ) : (
            pageProps.shop.title
          )}
        </a>
      </Link>

      <span className="d-lg-none d-inline-block ms-auto me-4">
        <Link href="/checkout">
          <a className="nav-link position-relative btn px-0">
            <FontAwesomeIcon icon={faShoppingBasket} size="lg" className="text-primary" />
            {showCheckoutDot && (
              <span className="position-absolute top-25 start-100 translate-middle badge border border-light rounded-circle bg-secondary p-2">
                <span className="visually-hidden">notifications</span>
              </span>
            )}
          </a>
        </Link>
      </span>

      <button
        className="navbar-toggler border-0 position-relative px-0"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navMenu"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        id="nav-toggler"
      >
        <span className="navbar-toggler-icon" />
        {/** TODO: Move this to a component */}
        {hasNotification && (
          <span className="position-absolute top-25 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2">
            <span className="visually-hidden">notifications</span>
          </span>
        )}
      </button>

      <div className="collapse navbar-collapse" id="navMenu">
        <div className="navbar-nav border-start ps-3 d-none d-lg-block">
          <SearchBox />
        </div>

        <p className="mx-auto my-auto">
          <small>{pageProps.shop.home?.promotionalText}</small>
        </p>

        <ul className="navbar-nav">
          {getSocials(pageProps).map((social) => {
            return (
              <li key={social.url} className="nav-item d-none d-lg-block">
                <a href={social.url} className="nav-link" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={iconMap[social.site]} />
                </a>
              </li>
            )
          })}
          <li className="nav-item" onClick={closeMenu}>
            <Link href="/about">
              <a className="nav-link">About us</a>
            </Link>
          </li>
          <li className="nav-item" onClick={closeMenu}>
            <Link href="/reviews">
              <a className="nav-link">Reviews</a>
            </Link>
          </li>
          <li className="nav-item" onClick={closeMenu}>
            <Link href="/checkout">
              <a className="nav-link position-relative">
                <FontAwesomeIcon icon={faShoppingBasket} /> Checkout
                {showCheckoutDot && (
                  <span className="position-absolute top-25 start-100 translate-middle badge border border-light rounded-circle bg-secondary p-2 d-none d-lg-inline">
                    <span className="visually-hidden">notifications</span>
                  </span>
                )}
              </a>
            </Link>
          </li>
          <AuthWidget shop={pageProps.shop} hasNotification={hasNotification} />
        </ul>
      </div>
    </nav>
  )
}
