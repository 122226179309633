import { getPreference, setPreference } from '../preferences'
import { useCallback, useEffect, useState } from 'react'

export default function useCart() {
  const [items, setItems] = useState([])

  const get = useCallback(
    function _get(product, variant) {
      return items.find(({ product: { _id }, variant: v }) => product._id === _id && v === variant)
    },
    [items]
  )

  const addItem = useCallback(
    function _addItem(product, variant) {
      const existing = get(product, variant)
      if (existing) {
        return
      }

      setItems([...items, { count: 1, product, variant }])
    },
    [get, items]
  )

  const changeCount = useCallback(
    function _changeCount(product, variant, count) {
      const existing = get(product, variant)

      const index = items.indexOf(existing)
      items.splice(index, 1, { count, product, variant })

      setItems([...items])
    },
    [get, items]
  )

  const removeItem = useCallback(
    function _removeItem(product, variant) {
      const existing = get(product, variant)
      items.splice(items.indexOf(existing), 1)
      setItems([...items])
    },
    [get, items]
  )

  const clear = useCallback(function _clear() {
    setItems([])
  }, [])

  useEffect(() => {
    try {
      const cart = getPreference('cart')
      setItems(cart.version !== 2 ? [] : cart.items)
    } catch (err) {
      setItems([])
    }
  }, [])

  useEffect(() => {
    setPreference('cart', { items, version: 2 })
  }, [items])

  return {
    addItem,
    changeCount,
    clear,
    contains: get,
    items,
    removeItem,
  }
}
