// Custom Lodashes

/**
 * @param {array} arr
 */
export function _contains(arr, value) {
  return arr.includes(value)
}

/**
 *
 * @param {array} arr
 * @param {(v) => boolean} predicate
 */
export function _any(arr, predicate) {
  for (let i = 0; i < arr.length; i++) {
    if (predicate(arr[i])) return true
  }

  return false
}

/**
 * Fills an array to meet block size of `length` using the `dummy` generator
 * @param {array} arr Array to fill
 * @param {number} length max length of array
 * @param {function} dummy function to generate dummary data
 */
export function _fill(arr, length, dummy) {
  const remainder = length - arr.length
  let fill = []

  if (remainder > 0) {
    for (let i = 0; i < remainder; i++) {
      fill.push(dummy(i))
    }
  }

  return [...fill, ...arr]
}
