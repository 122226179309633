import { AuthContext } from '../lib/contexts/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { login } from '../lib/utils'
import { useContext } from 'react'

export default function AuthWidget({ shop, hasNotification }) {
  const { isAdmin, logout: authLogout, user } = useContext(AuthContext)

  function logout() {
    authLogout()
    window.location.href = '/'
  }

  if (!shop) {
    return null
  }

  return user ? (
    <>
      <li className="nav-item">
        <div className="dropdown py-2 ms-lg-3 cursor-pointer">
          <span data-bs-toggle="dropdown">Your account</span>

          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <Link href="/account">
                <a className="dropdown-item">Profile</a>
              </Link>
            </li>
            {isAdmin && (
              <li>
                <Link href="/admin/">
                  <a className="dropdown-item">Admin Dashboard</a>
                </Link>
              </li>
            )}
            <li>
              <Link href="">
                <a className="dropdown-item" onClick={logout}>
                  Log out
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </li>

      <li className="nav-item">
        <Link href="/account/notifications" passHref>
          <button type="button" className="btn position-relative p-1 px-0 px-lg-1 ms-lg-3">
            <FontAwesomeIcon
              icon={faBell}
              size="lg"
              className={'d-none d-lg-inline ' + (!hasNotification ? 'text-muted' : '')}
            />
            <span className="d-inline d-lg-none">Notifications</span>
            {hasNotification && (
              <span className="position-absolute top-25 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2">
                <span className="visually-hidden">notifications</span>
              </span>
            )}
          </button>
        </Link>
      </li>
    </>
  ) : (
    <li className="nav-item">
      <button className="btn btn-secondary nav-link text-white px-2" onClick={login}>
        Login
      </button>
    </li>
  )
}
