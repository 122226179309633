import PubNav from '../components/PubNav'

export default function MainLayout({ children, pageProps }) {
  return (
    <>
      <PubNav pageProps={pageProps} />
      <div style={{ minHeight: '80vh' }}>{children}</div>
    </>
  )
}
