import { createContext, useEffect } from 'react'
import useAuth from '../hooks/use-auth'

export const AuthContext = createContext()

export default function AuthContextProvider({ children }) {
  const auth = useAuth()

  useEffect(() => {}, [auth])

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
