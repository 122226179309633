export const getPreference = (key) => {
  const pref = localStorage.getItem(key)

  if (pref) {
    return JSON.parse(pref)
  }

  throw new Error(`Preference with key [${key}] not found `)
}

export const setPreference = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const removePreference = (key) => {
  localStorage.removeItem(key)
}
